import React from 'react'

const Home = () => {
  return (
    <div className="card">
      <div className="card-body">
        Главная страница
      </div>
    </div>
  )
}

export default Home
