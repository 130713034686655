import React from 'react'
import {PageHeader} from '../../styledComponents/components'

const Resume = (props) => {
  return (
    <div>
      <PageHeader title='Резюме' />
      <div className="page-container">
        <div className="card">
          123
        </div>
      </div>
    </div>
  )
}


export default Resume
