import React from 'react'
import { Leftovers } from '../../pages/Storage/Leftovers'

class LeftoversContainer extends React.Component {
    render() {
        return <Leftovers/>
    }
}

export default LeftoversContainer
